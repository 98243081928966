//hotlead type we have so far
/*  GoSeeThisHomeHotleadInResponsiveDPP, (hotlead bottons)
    FixedHotleadInResponsiveDPP, (stikcy hotlead & chat leads)
    PhotoViewHotleadInResponsiveDPP, (pop view header hotlead button)
    All_Photos_Bottom_Active, (photo view bottom inline form)
    VirtualTourHotleadInResponsiveDPP, (virtual tour button)
    MapSearchInlineBottom, (all the msp hotleads)
    ListingAgentQuestionResponsiveDPP, (inline bottom form)
    openHouseCalendarHotlead, (openhouse button)
    InlineHotleadInResponsiveDPP (schedule button)
*/
import mvtCalendar from '@/common/components/mvtcalendar/mvt-calendar.vue';
import mvtCardAgent from '@/common/components/mvtcardagent/mvt-cardagent.vue';
import mvtCheckbox from '@/common/components/mvtcheckbox/mvt-checkbox.vue';
import mvtInputDate from '@/common/components/mvtinputdate/mvt-inputdate.vue';
import mvtInputGeo from '@/common/components/mvtinputgeo/mvt-inputgeo.vue';
import mvtInputNumber from '@/common/components/mvtinputnumber/mvt-inputnumber.vue';
import mvtRadio from '@/common/components/mvtradio/mvt-radio.vue';
import mvtSelect from '@/common/components/mvtselect/mvt-select.vue';
import mvtTextbox from '@/common/components/mvttextbox/mvt-textbox.vue';
import mvtMapStatic from '@/common/components/mvtmapstatic/mvt-mapstatic.vue';
import mapper from '@/common/store/mapper/index.js';
import format from '@/common/utilities/format';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import motime from '../../../common/utilities/motime';
import { getPathBySearchObj } from '../../../../widget/spec/searchHelper';
import hlConf from '@/common/store/glb/data.hotleadconfig';
import { fetchCachedClientTreatments, formatSplitsForGA4 } from '../split/utils.js';
import SplitTreatments from '@/common/components/split/SplitTreatments.vue';
import { partnerType, fallbackPartner, getStaticPartnerId } from '../../utilities/partnerType.js';
const getMinMoveInDate = () => {
    var date = new Date();
    return motime.formatDate(date);
};

const getDefaultDate = (options) => {
    if (!options || !options.propertyData || !options.propertyData.isRentals) {
        return '';
    }
    var date = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    return motime.formatDate(date);
};

export default {
    name: 'mvtHotleadForm',
    inject: ['$eventBus', 'split'],
    props: {
        options: {
            type: Object,
            default: function () {
                return {
                    treatment2775: null,
                    treatment2903: null,
                    leadGroupName: '',
                    trigger: null,
                    container: null,
                    email: '',
                    name: '',
                    phone: '',
                    price: '',
                    propertyType: '',
                    bath: '',
                    bed: '',
                    hotleadSup: '',
                    hotleadTitle: '',
                    hotleadSub: '',
                    profileUrl: '',
                    hideProfile: false,
                    profileHighlighter: false,
                    hotleadType: '',
                    theme: '',
                    buttonText: '',
                    propertyId: '',
                    comment: '',
                    propertyData: null,
                    agentData: null,
                    requestAgentId: null,
                    leadSource: '',
                    showAddress: false,
                    showZipcode: false,
                    showGeo: false,
                    showComment: null,
                    hideVeteran: false,
                    date: '',
                    overwriteLeadTypeToBuy: false,
                    timeDisplay: null,
                    enableChangeDate: false,
                    optionalDate: false,
                    surveyInfo: null,
                    hideIncentive: false,
                    rentalNearbyAffordUrl: '',
                    fromMyAgent: false,
                    hideDisclaimer: false,
                    autoSend: false,
                    showCashOffer: false,
                    showInterestOptions: false,
                    askAgentBox: false,
                };
            },
        },
        assignedAgent: Object,
    },
    components: {
        mvtCheckbox,
        mvtInputNumber,
        mvtCalendar,
        mvtTextbox,
        mvtInputGeo,
        mvtCardAgent,
        mvtInputDate,
        mvtRadio,
        mvtSelect,
        mvtMapStatic,
        SplitTreatments,
    },
    data: function () {
        return {
            initialed: false,
            partnerProfiles: null,
            treatment: {},
            showStreetView: false,
            forceInputPrice: false,
            loadXlead: false,
            editEmail: false,
            editFullName: false,
            editPhone: false,
            readEmail: false,
            readFullName: false,
            readPhone: false,
            popDialogBox: false,
            showBuySellError: false,
            hasDefaultPhone: false,
            isVeteran: false,
            acceptTerms: true,
            isMovotoHomeLoans: false,
            mortgage: null,
            mortgageFormCTA: 'Find a Lender',
            noSecondaryLead: true,
            errorMsg: '',
            buttonClass: '',
            hasLeadId: false,
            calendar: {
                hotleadType: undefined,
                date: undefined,
                timeDisplay: undefined,
            },
            isCashOffer: false,
            enquiryTypesData: [
                {
                    text: this.molang('common.enquiryType.placeholder'),
                    value: 0,
                },
                {
                    text: this.molang('common.enquiryType.one'),
                    value: 1,
                },
                {
                    text: this.molang('common.enquiryType.two'),
                    value: 2,
                },
                {
                    text: this.molang('common.enquiryType.three'),
                    value: 3,
                },
                {
                    text: this.molang('common.enquiryType.four'),
                    value: 4,
                },
                {
                    text: this.molang('common.enquiryType.five'),
                    value: 5,
                },
                {
                    text: this.molang('common.enquiryType.six'),
                    value: 6,
                },
                {
                    text: this.molang('common.enquiryType.seven'),
                    value: 7,
                },
                {
                    text: this.molang('common.enquiryType.eight'),
                    value: 8,
                },
            ],
            interestOptions: [
                {
                    text: this.molang('common.interestOptions.placeholder'),
                    value: 0,
                },
                {
                    text: this.molang('common.interestOptions.three'),
                    value: 3,
                },
                {
                    text: this.molang('common.interestOptions.two'),
                    value: 2,
                },
                {
                    text: this.molang('common.interestOptions.four'),
                    value: 4,
                },
                {
                    text: this.molang('common.interestOptions.five'),
                    value: 5,
                },
                {
                    text: this.molang('common.interestOptions.notsure'),
                    value: 1,
                },
                {
                    text: this.molang('common.interestOptions.six'),
                    value: 6,
                },
            ],
            interestedIn: 0,
            veteransTypeData: [
                {
                    value: '',
                    text: 'Select your branch of service',
                },
                {
                    value: 'Army',
                    text: 'Army',
                },
                {
                    value: 'Marine Corps',
                    text: 'Marine Corps',
                },
                {
                    value: 'Navy',
                    text: 'Navy',
                },
                {
                    value: 'Air Force',
                    text: 'Air Force',
                },
                {
                    value: 'Coast Guard',
                    text: 'Coast Guard',
                },
                {
                    value: 'Military Spouse',
                    text: 'Military Spouse',
                },
            ],
            employmentOptions: [
                { value: '', text: 'Please Select' },
                { value: 'employed', text: 'Employed' },
                { value: 'notEmployed', text: 'Not employed' },
                { value: 'selfEmployed', text: 'Self employed' },
                { value: 'military', text: 'Military' },
                { value: 'retired', text: 'Retired' },
                { value: 'other', text: 'Other' },
            ],
            incomeOptions: [
                { value: '', text: 'Please Select' },
                { value: '250000 or Higher', text: '$250,000 or Higher' },
                { value: '150000-250000', text: '$150,000 to $250,000' },
                { value: '100000-150000', text: '$100,000 to $150,000' },
                { value: '50000-100000', text: '$50,000 to $100,000' },
                { value: 'Below 50000', text: 'Below $50,000' },
            ],
            creditScoreOps: [
                { value: '', text: 'Please Select' },
                { value: '740 or Higher', text: '740 or Higher' },
                { value: '690 - 739', text: '690 - 739' },
                { value: '640 - 689', text: '640 - 689' },
                { value: 'Below 640', text: 'Below 640' },
            ],
            selectedEmployment: '',
            selectedIncome: '',
            selectedCreditScore: 'Below 640',
            formState: '',
            acFormErrorMsg: '',
            hotlead: {
                enquiryType: 0,
                veteransType: '',
                email: '',
                name: '',
                phone: '',
                zipcode: '',
                type: '',
                comment: '',
                leadSource: '',
                requestAgentId: '',
                isSeller: false,
                propertyId: '',
                cta: '',
                pageURL: '',
                propertyUrl: '',
                userAgent: '',
                address: '',
                xLeadId: '',
                price: '',
                moveIn: getDefaultDate(this.options),
                submission_attrs: {
                    is_veteran: null,
                    mortgage: {},
                    utm: {},
                },
                rentalNearbyAffordUrl: '',
            },
            acFormLeadType: null,
            acFormLeadCheckList: [
                {
                    text: 'Buyer',
                    value: 'Buyer',
                },
                {
                    text: 'Seller',
                    value: 'Seller',
                },
                {
                    text: 'Renter',
                    value: 'Renter',
                },
            ],
            messageAgentLeadType: null,
            messageAgentLeadTypeList: [
                {
                    text: this.molang('dpp.hotButton.buy'),
                    value: 'buy',
                },
                {
                    text: this.molang('dpp.hotButton.sell'),
                    value: 'sell',
                },
                {
                    text: this.molang('dpp.hotButton.both'),
                    value: 'both',
                },
                {
                    text: this.molang('dpp.hotButton.other'),
                    value: 'other',
                },
            ],
            assignedAgentCtaLinks: [
                {
                    text: 'Request a tour',
                    value: 'request-tour',
                },
                {
                    text: 'See similar homes',
                    value: 'similar-homes',
                },
                {
                    text: 'Start an offer',
                    value: 'start-offer',
                },
            ],
            activeCTAIndex: null,
            theme: '',
            hotleadTitle: '',
            testGroups: null,
            buttonText: '',
            showZipcode: false,
            showAddress: false,
            showComment: null,
            minMoveInDate: getMinMoveInDate(),
            addressInput: {
                text: '',
                geo: null,
            },
            userType: '',
            fallbackComment: '',
            hotleadCount: 0,
            timer: 0,
            phoneLeadList: [],
            propertyData: null,
            loopCount: 2,
            isLenderApiLoading: false,
        };
    },
    methods: {
        ...mapActions('glb', ['sendHotlead', 'fetchLender', 'sendZendeskRequest', 'preCheckPhone', 'sendPhoneOnlyLeadEmail', 'getHousesBySearchUrl', 'getAvmByPropertyId', 'getPartners']),
        ...mapMutations('glb', ['updatePrimaryAgent', 'updatePrevHotlead']),
        ...mapGetters('glb', ['getComplianceMethod']),
        async reloadPartner() {
            let payload = {
                language: this.glb.language === 'es' ? 'es' : 'en',
                isMobile: this.glb.isMobile,
                overwriteLeadTypeToBuy: this.options.overwriteLeadTypeToBuy,
                city: this.hotlead?.cityName,
                state: this.hotlead?.state,
                zipcode: this.hotlead?.zipcode,
                hotLeadValue: this.hotlead.type,
            };
            if (this.propertyData) {
                payload.listing = {
                    price: this.propertyData.price,
                    propertyType: this.propertyData.propertyType,
                    isPrOnly: this.propertyData.isPrOnly,
                    isSold: this.propertyData.isSold,
                    isRentals: this.propertyData.isRentals,
                    isNHS: this.propertyData.isNHS,
                    houseRealStatus: this.propertyData.houseRealStatus,
                    listingByMovoto: this.propertyData.listingByMovoto,
                    listingAgentData: this.propertyData.listingAgentData || { isMovotoAgent: false },
                };
                payload.mlsId = this.propertyData.mlsId?.toString();
            }
            if (payload.listing || payload.zipcode) {
                let partners = await this.getPartners(payload);
                if (partners) {
                    //start code for 2775 off temp use
                    let item = partners.find((partner) => partner.type === partnerType.proplus);
                    if (this.glb.language === 'es') {
                        let result = await this.split.fetchTreatments(['movoto-CW-2903']);
                        this.treatment2903 = result['movoto-CW-2903'];
                    }
                    if (item && !this.treatment2775) {
                        let result = await this.split.fetchTreatments(['movoto-CW-2775']);
                        this.treatment2775 = result['movoto-CW-2775'];
                    }
                    if (item && this.treatment2775 !== 'on1') {
                        partners = partners.filter((partner) => partner.type !== partnerType.proplus);
                    }
                    //end code for 2775 off temp use
                    this.partnerProfiles = partners;
                }
            }
            return new Promise((resolve) => {
                resolve();
            });
        },
        updateSubmitBtnClass() {
            if (!this.hotlead.email || !this.hotlead.phone || !this.hotlead.name) {
                this.buttonClass = 'btn tertiary submit-dim-gray';
            } else {
                this.buttonClass = 'btn primary';
            }
        },
        async updateSplit() {
            const result = await this.split.fetchTreatments(['movoto-save-property-onlead-CW-2289', 'movoto-mhl-copy-update-CW-2302']);
            this.treatment = result;
            this.hotlead.isSaveFavoriteSplit = this.treatment['movoto-save-property-onlead-CW-2289'] === 'on' ? false : true;
        },
        showBuyerAgentCTA() {
            const compliance = this.getComplianceMethod()();
            let compRule = compliance[0] && compliance[0].rule;
            return compRule && compRule.indexOf('showBuyerAgentCTA') >= 0;
        },
        checkAutoSend() {
            return (
                this.glb.user.id &&
                this.glb.user.fullName &&
                this.glb.user.phone &&
                this.glb.user.email &&
                !this.options.showAddress &&
                !this.options.showZipcode &&
                !this.options.showGeo &&
                !this.options.showComment &&
                !this.showVeteransOption &&
                !this.options.enableChangeDate &&
                !this.showCashOfferCheckbox &&
                !this.showMovotoHomeLoans
            );
        },
        updateHotleadByProperty(propertyData) {
            if (!propertyData) return;
            let mapUrls = propertyData.mapUrls;
            this.hotlead.propertyType = propertyData.propertyType;
            this.hotlead.price = propertyData.price;
            this.hotlead.bed = propertyData.bed;
            this.hotlead.bath = propertyData.bath;
            this.hotlead.propertyId = propertyData.propertyId;
            this.hotlead.listingId = propertyData.listingId;
            this.hotlead.zipcode = propertyData.geo.zipcode;
            this.hotlead.propertyUrl = propertyData.dppUrl;
            this.hotlead.path = propertyData.path;
            this.hotlead.requestAgentId = (propertyData.dppAgent && propertyData.dppAgent.agentId) || this.options.requestAgentId || '';
            this.hotlead.heroImageURL = (propertyData.photoCount && propertyData.tnImgPath) || '';
            this.hotlead.staticMapUrl = mapUrls && mapUrls.length > 2 ? mapUrls[2].src : '';
            this.hotlead.address = propertyData.geo.address || '';
            this.hotlead.cityName = propertyData.geo.city || '';
            this.hotlead.state = propertyData.geo.state || '';
            this.hotlead.mlsId = propertyData.mlsId || '';
        },
        updateHotleadByRentProperty(propertyData, defaultInfo) {
            if (!propertyData) return;
            const rentConfig = hlConf[propertyData.mlsDbNumber];
            this.hotlead.zumperListingId = this.hotlead.rentalListingId = propertyData.rentalListingId || '';
            this.hotlead.buildingId = propertyData.rentalBuildingId || '';
            this.hotlead.floorPlanId = propertyData.rentalFloorPlanId || '';
            this.hotlead.type = (defaultInfo && defaultInfo.rentHotleadType) || rentConfig['NewRentalRequestInfo'];
        },
        setAgentLeadType(val) {
            var deviceType = this.glb.isMobile ? 'Mobile' : 'Desktop';
            if (val == 'buy') {
                this.hotlead.type = 'OffMarketCombinationBuy_' + deviceType;
            } else if (val == 'sell') {
                this.hotlead.type = 'OffMarketCombinationSell_' + deviceType;
            } else if (val == 'both') {
                this.hotlead.type = 'OffMarketCombinationBuySell_' + deviceType;
            } else {
                if (this.propertyData.houseRealStatus === 'OFF_MARKET') {
                    this.hotlead.type = '_FixedHotleadInResponsivePrDPP' + (this.glb.isMobile ? 'Mobile' : '');
                } else if (this.propertyData.houseRealStatus === 'SOLD') {
                    this.hotlead.type = '_FixedHotleadInResponsiveSoldDPP' + (this.glb.isMobile ? 'Mobile' : '');
                }
            }
        },
        openRequestAssistance() {
            this.$eventBus.$emit('dialog-center', {
                name: 'myConcierge',
                opt: {},
                trigger: this.trigger,
            });
        },
        changeActiveCTA(index, value) {
            this.activeCTAIndex = index;
            switch (value) {
                case 'request-tour':
                    this.hotlead.comment = `Hi ${this.glb.agent.firstName}, I'd like to request a tour of ${this.propertyData.geo.formatAddress}.`;
                    break;
                case 'similar-homes':
                    this.hotlead.comment = `Hi ${this.glb.agent.firstName}, I'd like to see homes similar to ${this.propertyData.geo.formatAddress}.`;
                    break;
                case 'start-offer':
                    this.hotlead.comment = `Hi ${this.glb.agent.firstName}, I'd like to start an offer for ${this.propertyData.geo.formatAddress}.`;
                    break;
                case 'request-disclosures':
                    this.hotlead.comment = `Hi ${this.glb.agent.firstName}, I'd like to request disclosures for ${this.propertyData.geo.formatAddress}.`;
                    break;
            }
        },
        getdefaultInfo() {
            var defaultUI = {
                hotleadTitle: '',
                buttonText: this.molang('dpp.hotButton.contactAgent'),
                comment: '',
            };
            if (this.propertyData) {
                var hotleadTitle, buttonText, commentText;
                var displayDateTitle = this.calendar.date ? ` on ${motime.formatDate(this.calendar.date, { month: 'short', day: 'numeric', raw: true })}` : '';
                var dispalyDateComment = this.calendar.date ? ` on ${motime.formatDate(this.calendar.date, { weekday: 'long', month: 'long', day: 'numeric', raw: true })}` : '';
                if (this.options.time) {
                    dispalyDateComment += ' at ' + this.options.time;
                }
                var hotleadType = this.calendar.hotleadType || this.options.hotleadType;

                if (hotleadType === 'ListingAgentQuestionResponsiveDPP') {
                    var title = this.propertyData.isActive ? this.molang('dpp.hotleadForm.askQuestion') : this.molang('dpp.hotleadForm.listingAgent');
                    if (this.propertyData.isRentals) {
                        title = this.molang('dpp.hotleadForm.requestInfo');
                    } else if (this.propertyData.isNHS) {
                        title = this.molang('dpp.hotleadForm.askBuilder');
                    }
                    defaultUI = {
                        hotleadTitle: title,
                        buttonText: this.molang('dpp.propertyAgents.askQuestion'),
                        comment: `${this.molang('dpp.hotleadForm.questionAbout')} ${this.propertyData.geo.formatAddress}.`,
                    };

                    if (this.hasOJOAgent) {
                        defaultUI.buttonText = this.molang('dpp.hotleadForm.askOjoAgent', { firstName: this.glb.agent.firstName });
                        defaultUI.hotleadTitle = this.molang('dpp.hotleadForm.askOjoAgent', { firstName: this.glb.agent.firstName });
                    }
                } else if (hotleadType === 'All_Photos_Bottom_Active') {
                    hotleadTitle = this.propertyData.isActive
                        ? this.showBuyerAgentCTA()
                            ? this.molang('dpp.hotleadForm.scheduleViewingAgent')
                            : this.molang('dpp.hotleadForm.scheduleViewing')
                        : this.molang('dpp.hotleadForm.tourNearby');
                    buttonText = this.molang('dpp.hotleadForm.go');
                    if (this.propertyData.isRentals) {
                        hotleadTitle = this.molang('dpp.hotleadForm.tourPlace');
                        buttonText = this.molang('dpp.hotleadForm.requestTour');
                    }
                    defaultUI = {
                        hotleadTitle: hotleadTitle,
                        buttonText: buttonText,
                        comment: this.propertyData.isActive
                            ? `${this.molang('dpp.hotleadForm.interestIn')} ${this.propertyData.geo.formatAddress}.`
                            : `${this.molang('dpp.hotleadForm.interestInSimilar')} ${this.propertyData.geo.formatAddress}.`,
                    };

                    if (this.glb.agent.id) {
                        this.hotlead.isOJOAgentLead = true;
                        defaultUI.hotleadTitle = this.propertyData.isActive
                            ? `${this.molang('dpp.hotleadForm.scheduleViewing')} with ${this.glb.agent.firstName}`
                            : `${this.molang('dpp.hotleadForm.tourNearby')} with ${this.glb.agent.firstName}`;
                    }
                } else if (hotleadType === 'InlineHotleadInResponsiveDPP') {
                    var isSaleOrRent = false;
                    buttonText = 'go';
                    if (this.propertyData.houseRealStatus === this.glb.propertyStatus.forRent) {
                        hotleadTitle = this.molang('dpp.propertySchedule.tourThisProperty');
                        isSaleOrRent = true;
                    } else if (this.propertyData.houseRealStatus === this.glb.propertyStatus.active) {
                        hotleadTitle = this.molang('dpp.propertySchedule.tourThisHome');
                        isSaleOrRent = true;
                    } else if (this.trigger === 'dppHotBanner' && (this.propertyData.isSold || this.propertyData.isPrOnly)) {
                        hotleadTitle = this.molang('dpp.propertySchedule.scheduleConsultation');
                    } else {
                        hotleadTitle = 'Tour Similar Homes';
                    }

                    defaultUI = {
                        hotleadTitle: displayDateTitle ? `${hotleadTitle}${displayDateTitle}` : '',
                        buttonText: buttonText,
                        comment: isSaleOrRent
                            ? `${this.molang('dpp.hotleadForm.interestIn')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`
                            : `${this.molang('dpp.hotleadForm.interestInSimilar')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`,
                    };
                    if (this.hasOJOAgent) {
                        defaultUI.comment = `Hi ${this.glb.agent.firstName} ${this.molang('dpp.hotleadForm.interestIn')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`;
                    }
                } else if (hotleadType === 'VirtualTourHotleadInResponsiveDPP') {
                    defaultUI = {
                        hotleadTitle: displayDateTitle ? `${this.molang('dpp.hotleadForm.scheduleVirtual')}${displayDateTitle}` : 'Virtual Tour',
                        buttonText: this.molang('dpp.hotleadForm.go'),
                        comment: this.propertyData.isActive
                            ? `${this.molang('dpp.hotleadForm.virtualTour')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`
                            : `${this.molang('dpp.hotleadForm.virtualTourSimilar')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`,
                        nhsHotleadType: 'NewHomeSource_RequestAppointment',
                    };
                    if (this.hasOJOAgent) {
                        defaultUI.buttonText = this.molang('dpp.hotButton.messageAssignedAgent');
                        defaultUI.comment = `Hi ${this.glb.agent.firstName}, I have a question about ${this.propertyData.geo.formatAddress}.`;
                    }
                } else if (hotleadType === 'hotHomeHotLead') {
                    defaultUI = {
                        hotleadTitle: displayDateTitle ? `${this.molang('dpp.propertySchedule.tourThisHome')}${displayDateTitle}` : '',
                        buttonText: this.molang('dpp.hotleadForm.go'),
                        comment: `${this.molang('dpp.hotleadForm.interestIn')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`,
                    };
                    if (this.hasOJOAgent) {
                        defaultUI.buttonText = this.molang('dpp.hotleadForm.requestTour');
                        defaultUI.comment = `Hi ${this.glb.agent.firstName}, ${this.molang('dpp.hotleadForm.interestIn')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`;
                    }
                } else if (hotleadType === 'PriceEstimate_sellerMobile' || hotleadType === 'PriceEstimate_sellerPC') {
                    defaultUI = {
                        hotleadTitle: displayDateTitle ? `${this.molang('dpp.propertySchedule.tourThisHome')}${displayDateTitle}` : '',
                        buttonText: this.molang('dpp.hotleadForm.contactNow'),
                        comment: `${this.molang('dpp.hotleadForm.interestIn')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`,
                    };
                    if (this.hasOJOAgent) {
                        defaultUI.buttonText = this.molang('dpp.hotleadForm.requestTour');
                        defaultUI.comment = `Hi ${this.glb.agent.firstName}, ${this.molang('dpp.hotleadForm.interestIn')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`;
                    }
                } else if (hotleadType === 'openHouseCalendarHotlead' || hotleadType === 'TourAsPrimaryCTA' || hotleadType === 'TourOnHeroPhoto') {
                    defaultUI = {
                        hotleadTitle: this.options.hotleadTitle || (displayDateTitle ? `${this.molang('dpp.hotleadForm.attendOpenHouse')}${displayDateTitle}` : ''),
                        buttonText: this.molang('dpp.hotleadForm.go'),
                        comment: `${this.molang('dpp.hotleadForm.interestedInOpen')} ${this.propertyData.geo.formatAddress}${dispalyDateComment} with a top local real estate agent.`,
                    };
                    if (this.hasOJOAgent) {
                        defaultUI.buttonText = this.molang('dpp.hotleadForm.requestTour');
                        defaultUI.comment = `Hi ${this.glb.agent.firstName}, ${this.molang('dpp.hotleadForm.interestedInOpen')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`;
                    }
                } else if (hotleadType === 'nonguaranteedSalehotlead_bottom') {
                    defaultUI = {
                        hotleadTitle: displayDateTitle ? `${this.molang('dpp.propertySchedule.scheduleConsultation')}${displayDateTitle}` : '',
                        buttonText: this.molang('dpp.hotleadForm.go'),
                        comment: `${this.molang('dpp.hotleadForm.interestIn')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`,
                    };
                    if (this.hasOJOAgent) {
                        defaultUI.buttonText = this.molang('dpp.hotleadForm.requestTour');
                        defaultUI.comment = `Hi ${this.glb.agent.firstName}, ${this.molang('dpp.hotleadForm.interestIn')} ${this.propertyData.geo.formatAddress}${dispalyDateComment}.`;
                    }
                } else if (hotleadType === 'PhotoViewHotleadInResponsiveDPP' || hotleadType === 'GoSeeThisHomeHotleadInResponsiveDPP') {
                    if (this.propertyData.isActive || this.propertyData.isPrOnly) {
                        buttonText = this.molang('dpp.hotButton.requestInfo');
                        commentText = `${this.molang('dpp.hotleadForm.interestedIn')} ${this.propertyData.geo.formatAddress}.`;
                        if (this.propertyData.isRentals) {
                            commentText = `${this.molang('dpp.hotleadForm.connectToManager')} ${this.propertyData.geo.formatAddress}.`;
                        }
                        defaultUI = {
                            hotleadTitle: '',
                            buttonText: buttonText,
                            comment: commentText,
                        };
                        if (this.hasOJOAgent) {
                            defaultUI.buttonText = this.molang('dpp.hotButton.manageAssignedAgent');
                            defaultUI.comment = `Hi ${this.glb.agent.firstName}, I have a question about ${this.propertyData.geo.formatAddress}.`;
                        }
                    } else {
                        buttonText = this.molang('dpp.hotButton.contactAgent');
                        commentText = `${this.molang('dpp.hotleadForm.connectAgent')} ${this.propertyData.geo.formatAddress || ''}.`;
                        defaultUI = {
                            hotleadTitle: '',
                            buttonText: buttonText,
                            comment: commentText,
                        };
                    }
                } else if (this.propertyData.isActive && this.options.askAgentBox) {
                    defaultUI = {
                        hotleadTitle: this.molang('dpp.hotButton.askAgent'),
                        buttonText: this.molang('dpp.hotButton.askAgent'),
                        comment: this.getSplit('movoto-ask-agent-cta-CW-1968', 'on3') ? `I have a question about ${this.propertyData.geo.formatAddress}` : '',
                    };
                } else if (this.propertyData.isActive || this.propertyData.isPrOnly) {
                    defaultUI = {
                        hotleadTitle: '',
                        buttonText: this.molang('dpp.hotButton.requestInfo'),
                        comment: `${this.molang('dpp.hotleadForm.interestedIn')} ${this.propertyData.geo.formatAddress}.`,
                    };
                } else {
                    defaultUI = {
                        hotleadTitle: '',
                        buttonText: this.molang('dpp.hotButton.contactAgent'),
                        comment: `${this.molang('dpp.hotleadForm.connectAgent')} ${this.propertyData.geo.formatAddress || ''}.`,
                    };
                }
            } else {
                if (this.hasOJOAgent) {
                    defaultUI.comment = `Hi ${this.glb.agent.firstName}, I'd like to schedule time to connect.`;
                }
            }
            if (this.calendar.timeDisplay) {
                defaultUI.comment += ` (${this.calendar.timeDisplay})`;
            }
            return defaultUI;
        },
        updateHotleaUI() {
            var defaultInfo = this.getdefaultInfo();
            this.hotleadTitle = this.options.hotleadTitle || defaultInfo.hotleadTitle;
            this.buttonText = this.options.buttonText || defaultInfo.buttonText;
            this.hotlead.comment = this.fallbackComment = this.options.comment || defaultInfo.comment;
            if (!this.hotleadTitle) {
                this.hotleadTitle = this.buttonText;
                this.buttonText = this.molang('dpp.hotleadForm.go');
            }
            if (this.assignedAgent && this.assignedAgent.id) {
                this.buttonText = this.molang('dpp.hotleadForm.askOjoAgent', { firstName: this.assignedAgent.firstName });
                this.hotleadTitle = this.molang('dpp.hotleadForm.askOjoAgent', { firstName: this.assignedAgent.firstName });
            }
            if (this.calendar) {
                this.hotlead.scheduleDate = this.calendar.date * 1;
            }
            return defaultInfo;
        },
        createHotleadModel() {
            this.theme = this.options.theme || 'default';
            this.showAddress = this.options.showAddress || false;
            this.showZipcode = this.options.showZipcode || false;
            if (this.options.readEmail !== undefined) {
                this.readEmail = this.options.readEmail;
            }
            if (this.options.readFullName !== undefined) {
                this.readFullName = this.options.readFullName;
            }
            if (this.options.readPhone !== undefined) {
                this.readPhone = this.options.readPhone;
            }
            if (this.options.showComment !== null) {
                this.showComment = this.options.showComment;
            } else {
                this.showComment = !this.glb.isMobile;
            }
            this.calendar = {
                hotleadType: this.options.hotleadType,
                date: this.options.date ? new Date(this.options.date) : undefined,
                timeDisplay: this.options.timeDisplay || undefined,
            };
            if (this.options.propertyData) {
                this.addressInput = {
                    text: this.options.propertyData.geo.formatAddress,
                    geo: this.options.propertyData.geo,
                };
            }

            var defaultInfo = this.updateHotleaUI();
            var deviceType = this.glb.isMobile ? 'Mobile' : 'Desktop';

            //for hotlead
            this.hotlead.email = this.options.email || this.glb.hotleadInfo.email || this.glb.user.email || '';
            this.hotlead.name = this.options.name || this.glb.hotleadInfo.fullName || this.glb.user.fullName || '';
            this.hotlead.phone = this.options.phone || this.glb.hotleadInfo.phone || this.glb.user.phone || '';

            if (this.getSplit('movoto-leadconfirmation-CW-2152')) {
                if (this.hotlead.email && this.hotlead.email.split('@')[0] === this.hotlead.name) {
                    this.hotlead.name = '';
                }
                this.editFullName = true;
                this.readFullName = true;
                this.readPhone = true;
                this.editPhone = true;
                this.readEmail = true;
            }

            this.hasDefaultPhone = !!this.hotlead.phone;

            if (this.options.date > 0) {
                this.hotlead.scheduleDate = this.options.date * 1;
            }

            // analytics / tracking
            var page = this.glb.pageInfo || {};
            this.hotlead.channel = 'website';
            this.hotlead.pageName = page.value || '';
            this.hotlead.pageType = page.type || '';
            this.hotlead.pageSiteSection = page.sitesection || '';
            this.hotlead.landingPage = this.glb.landingPage || '';
            this.hotlead.ipAddress = this.glb.ip || '';
            if (!this.glb.isSSR) {
                let ga = $.getCookie('_ga');
                this.hotlead.googleAnalyticsId = ga ? ga.substring(6) : '';
            }
            this.hotlead.gclid = (!this.glb.isSSR && $.getCookie('gclid')) || '';
            this.hotlead.randomId = (!this.glb.isSSR && $.getCookie('RANDOMID')) || '';
            this.hotlead.userAgent = !this.glb.isSSR && navigator.userAgent;
            this.hotlead.hotLeadTimezone = !this.glb.isSSR && $.getTimezone();

            this.hotlead.propertyType = this.options.propertyType;
            this.hotlead.price = this.options.price;
            this.hotlead.bed = this.options.bed;
            this.hotlead.bath = this.options.bath;
            this.hotlead.propertyId = this.options.propertyId || '';
            this.hotlead.rentalNearbyAffordUrl = this.glb.rentalNearbyAffordUrl || '';
            this.hotlead.isSaveFavoriteSplit = true;
            if (!this.hotlead.isOJOAgentLead) {
                this.hotlead.isOJOAgentLead = this.hasOJOAgent;
            }
            this.hotlead.cta = this.buttonText;
            this.hotlead.type = this.options.hotleadType || '';
            this.hotlead.leadSource = this.options.leadSource || deviceType;
            this.hotlead.isSeller = this.options.isSeller;
            this.hotlead.pageURL = !this.glb.isSSR && window.location.href;
            this.hotlead.overwriteLeadTypeToBuy = this.options.overwriteLeadTypeToBuy || false;
            this.hotlead.deviceType = deviceType;
            this.hotlead.acquisitionChannel = this.glb.acquisitionChannel;
            !this.glb.isSSR &&
                $.getSharedGeoLocation().then((latlng) => {
                    if (latlng) {
                        this.hotlead.geoLocation = `${latlng.lat},${latlng.lng}`;
                    } else {
                        this.hotlead.geoLocation = '';
                    }
                });
            this.hotlead.deviceOS = this.glb.extendedOS;
            this.hotlead.browserTimezone = !this.glb.isSSR && $.getIANAtimeZone();
            this.hotlead.languageOfChoice = this.glb.language || 'en';
            if (this.propertyData && this.propertyData.propertyId) {
                this.updateHotleadByProperty(this.propertyData);
                if (this.propertyData.isRentals) {
                    this.updateHotleadByRentProperty(this.propertyData, defaultInfo);
                } else if (this.propertyData.isNHS && this.propertyData.isActive) {
                    this.hotlead.nhsPartnerId = 348;
                    this.hotlead.nhsLeadType = 'com';
                    if (this.propertyData.nhsListingId) {
                        if (this.propertyData.nhsListingType === 'New Construction') {
                            this.hotlead.nhsLeadType = 'hm';
                            this.hotlead.nhsSpecList = this.propertyData.nhsListingId;
                        } else if (this.propertyData.nhsListingType === 'Ready To Build') {
                            this.hotlead.nhsLeadType = 'hm';
                            this.hotlead.nhsPlanList = this.propertyData.nhsListingId;
                        }
                    }
                    this.hotlead.nhsCommunityId = this.propertyData.nhsCommunityId || '';
                    this.hotlead.nhsBuilderId = this.propertyData.nhsBuilderId || '';
                    this.hotlead.type = defaultInfo.nhsHotleadType || this.hotlead.type;
                }
            } else {
                this.hotlead.zipcode = this.options.zipcode || '';
                this.hotlead.propertyUrl = '';
                this.hotlead.requestAgentId = this.options.requestAgentId || '';
            }
            if (this.options.isVeteransHub || this.options.isVeteranDrawer) {
                this.isVeteran = true;
            }
        },
        validateEmail() {
            this.preCheckEmail({ email: this.hotlead.email })
                .then((res) => {
                    if (res && res.status && res.status.code === 0) {
                        this.hotlead.isValidEmail = true;
                        this.updateHotleaUI();
                    }
                })
                .catch((res) => {
                    if (res && res.status && res.status.code) {
                        this.errorMsg = this.getErrorHtml(res.status, res.data);
                        this.hasDefaultEmail = false;
                        return;
                    }
                });
        },
        validatePhone() {
            this.preCheckPhone({ phone: this.hotlead.phone })
                .then((res) => {
                    if (res && res.status && res.status.code === 0) {
                        this.hotlead.isValidPhone = true;
                        this.updateHotleaUI();
                    }
                })
                .catch((res) => {
                    if (res && res.status && res.status.code) {
                        this.errorMsg = this.getErrorHtml(res.status);
                        this.hasDefaultPhone = false;
                        return;
                    }
                });
        },
        async fetchListings() {
            return new Promise((resolve) => {
                let searchInfo = this.addressInput;
                if (searchInfo.geo && searchInfo.geo.formatted_address) {
                    let path = getPathBySearchObj(searchInfo.geo, {
                        rentals: this.acFormLeadType === 'Renter',
                    });

                    this.getHousesBySearchUrl({
                        path: path,
                        includeAllAddress: true,
                    })
                        .then((result) => {
                            if (result.listings && result.listings.length) {
                                let listings = result.listings.map((e) => {
                                    return mapper.property(e, this.glb);
                                });
                                resolve(listings);
                            }
                            resolve([]);
                        })
                        .catch(() => {
                            resolve([]);
                        });
                } else {
                    resolve([]);
                }
            });
        },
        async sending() {
            if (!this.interestedIn && this.options.showInterestOptions) {
                this.errorMsg = 'Please select an option';
                return Promise.reject();
            }
            if (this.interestedIn === 5 && this.options.showInterestOptions) {
                this.$eventBus.$emit('close-dialog');
                return Promise.reject();
            }
            if (this.formState === 'submitting') {
                window && window.sendNodeLog('VuehotleadSupmitting', window.location.href, '', { submitState: this.formState, email: this.hotlead.email });
                return Promise.reject();
            }
            this.formState = 'submitting';
            if (this.trigger) {
                this.hotlead.trigger = this.trigger;
            }
            const address = this.showZipcode || this.getShowAddress || this.showInputGeo || (this.showInputGeoOptional && this.addressInput);
            if (address && !this.forceInputPrice) {
                let listings = await this.fetchListings();
                let matchedHome;
                let valuedlistings = listings.filter((e) => e.price > 0);

                if (valuedlistings.length > 0) {
                    matchedHome = valuedlistings[0];
                    this.hotlead.price = Math.round(valuedlistings.reduce((a, b) => a + b.price, 0) / valuedlistings.length);
                } else if (listings.length > 0) {
                    matchedHome = listings[0];
                    let avm = await this.getAvmByPropertyId({ propertyId: matchedHome.propertyId });
                    if (avm && avm.valuation > 0) {
                        matchedHome.price = avm.valuation;
                        this.hotlead.price = avm.valuation;
                    } else {
                        this.hotlead.price = 350000;
                    }
                } else {
                    matchedHome = null;
                    this.hotlead.price = 350000;
                }
                if (this.needCheckListing) {
                    this.acFormErrorMsg = this.checkListing(matchedHome);
                    if (this.acFormErrorMsg) {
                        return Promise.reject();
                    }
                } else if (!this.isACForm && matchedHome) {
                    this.propertyData = matchedHome;
                    this.updateHotleadByProperty(this.propertyData);
                }
                if (!this.hotlead.price || this.hotlead.price <= 0) {
                    this.errorMsg = 'No medium price found for this area. Please enter a price.';
                    this.forceInputPrice = true;
                    return Promise.reject();
                }
            }

            if (this.propertyData) {
                //handle rent hotlead failures for no comments
                if (this.propertyData.isRentals && !this.hotlead.comment) {
                    this.hotlead.message = this.fallbackComment;
                }
                //setting staticmapUrl cw-9579
                if (this.propertyData.mapUrls && this.propertyData.mapUrls.length > 2) {
                    this.hotlead.staticMapUrl = this.propertyData.mapUrls[2].src;
                }
            }

            if (this.options.surveyInfo) {
                for (var key in this.options.surveyInfo) {
                    var value = this.options.surveyInfo[key];
                    if (value !== undefined && value !== null && value !== '') {
                        this.hotlead.comment += ` ${key}=${this.options.surveyInfo[key]},`;
                    }
                }
            }
            if (!this.isVeteran) {
                this.hotlead.veteransType = '';
            }

            if (this.isCashOffer) {
                //hackin code: use dpp in mvt component to made it works
                if (this.dpp && this.dpp.isActive && this.dpp.propertyId !== this.hotlead.propertyId) {
                    this.hotlead.type = 'CashOfferCheckboxActive';
                } else {
                    this.hotlead.type = 'CashOfferCheckbox';
                }
            }

            this.hotlead.rentalNearbyAffordUrl = this.glb.rentalNearbyAffordUrl || '';

            // need to update that agent to primary agent if user sends message from myagent page
            if (this.options.fromMyAgent && this.options.agentData) {
                this.updatePrimaryAgent(this.options.agentData.id);
            }
            this.hotlead.selectedAgentId = this.glb.agent.id;
            if (this.options.isVeteransHub && !this.hotlead.address) {
                this.hotlead.address = 'no address';
            }
            let submitMethod = this.hotlead.enquiryType > 2 ? 'sendZendeskRequest' : 'sendHotlead';

            this.hotlead.partnerId = this.partnerUIId;
            this.hotlead.consent = {
                context: {
                    purpose: ['real-estate'],
                    text: this.$refs.disclaimer ? this.$refs.disclaimer.textContent : '',
                    textVersion: '1.0.0',
                },
                profiles: this.partnerProfile ? [
                    {
                        id: this.partnerProfile.partnerId,
                        name: this.partnerProfile.partnerName,
                        type: this.partnerProfile.partnerType,
                    },
                ] : [],
            };

            if (this.partnerUIId === partnerType.proplus) {
                let teamName = this.hotlead.consent.profiles[0].name.toLowerCase().replace(/\s/g, '_');
                let defaultPartner = this.partnerProfiles[this.partnerProfiles.length - 1];
                let defaultProfile = {
                    id: defaultPartner.partnerId,
                    name: defaultPartner.partnerName,
                    type: defaultPartner.partnerType,
                };
                if (this.acceptTerms) {
                    this.hotlead.consent.profiles.push(defaultProfile);
                } else {
                    this.hotlead.consent.profiles[0] = defaultProfile;
                }
                this.$eventBus.$emit('ga', { en: 'tcpa_lead_sent', ec: `${this.partnerUIId}_${teamName}`, el: this.acceptTerms ? 'true' : 'false' });
            } else if (this.hotlead.consent) {
                this.$eventBus.$emit('ga', { en: 'tcpa_lead_sent', ec: this.partnerUIId ? `partner_${this.partnerUIId}` : 'movoto', el: 'true' });
            }

            // Get latest Split Treatment Values
            this.hotlead.testGroups = formatSplitsForGA4(this.glb.splits, fetchCachedClientTreatments(), true);
            try {
                let resp = await this[submitMethod]({
                    data: this.hotlead,
                    options: {
                        container: this.container,
                        trigger: this.trigger,
                        isACForm: this.isACForm,
                        leadGroupName: this.options.leadGroupName,
                        noSecondaryLead:
                            $.getStorage('SHOWHAVEHOMETOSELLFORM') ||
                            (this.propertyData && this.propertyData.isRentals) ||
                            this.userType === 'SELLER' ||
                            this.options.noSecondaryLead ||
                            this.options.isACForm,
                        isVeteran: this.isVeteran,
                        isMovotoHomeLoans: this.showMovotoHomeLoans ? this.isMovotoHomeLoans : undefined,
                        propertyData: this.propertyData,
                        veteransType: '',
                        showVeteranThankYou: this.showVeteranThankYou(),
                        redirectUrl: this.options.redirectUrl,
                    },
                });
                return Promise.resolve(resp);
            } catch (e) {
                return Promise.reject();
            }
        },
        loadingLender(isAllianceRealtyCriteria, hotleadId, phoneNumber) {
            var that = this;
            return new Promise(function (resolve) {
                let sendResponse = function (status) {
                    that.isLenderApiLoading = false;
                    that.loopCount = 0;
                    resolve(status);
                };
                let looper = function () {
                    if (!that.loopCount) {
                        return sendResponse(false);
                    }
                    that.loopCount--;
                    setTimeout(() => {
                        that.fetchLender({ hotleadId, phoneNumber, apiName: 'realEstatePurchase' })
                            .then((response) => {
                                if (!response || !response.data || response.data.referred === undefined) {
                                    looper();
                                } else {
                                    sendResponse(response.data.referred);
                                }
                            })
                            .catch((err) => {
                                looper();
                            });
                    }, 2000);
                };
                if (!isAllianceRealtyCriteria) {
                    sendResponse(false);
                } else {
                    that.isLenderApiLoading = true;
                    looper();
                }
            });
        },
        submit() {
            try {
                this.sending().then(this.successHandle).catch(this.errorHandle);
            } catch (e) {
                this.errorHandle();
            }
        },
        successHandle(resp) {
            var that = this;
            let status = resp && resp.status;
            this.$emit('submitted', resp);
            if (this.hasLeadId) {
                this.$eventBus.$emit('xlead-snap');
            }

            if (status && status.code === 0) {
                this.errorMsg = '';
                this.$eventBus.$emit('dialog-center-tip', `<span class="text-bold">Success!</span> <span class="text-medium">We will be in touch shortly</span>`);
                if (this.hotlead.propertyId) {
                    this.formState = 'submitted';
                } else {
                    setTimeout(() => {
                        this.formState = '';
                    }, 3000);
                }

                //todo here move to action
                if (resp.data.userType) {
                    this.userType = resp.data.userType;
                }
                this.hotlead.hotleadId = resp.data.hotLeadId;
                this.hotleadCount = resp.data.allHotleadCount;
                // for sell-with-an-agent for non logged in user take user id from hotlead
                if (this.options.isAgentSell) {
                    this.$eventBus.$emit('eventbus-hotlead-userid', resp.data.userId);
                }

                this.$emit('comfirmed');
                if (this.options.customGA) {
                    this.$eventBus.$emit('ga', this.options.customGA);
                }
                if (this.hasLeadId) {
                    this.$eventBus.$emit('xlead-stop');
                }
                const phoneNumber = this.hotlead.phone || this.glb.hotleadInfo.phone;
                const hotleadId = this.hotlead.hotleadId;
                // const hotleadCount = $.getStorage('hotleadCount');

                this.loadingLender(this.partnerProfile.type === partnerType.alliance, hotleadId, phoneNumber).then((lenderResp) => {
                    if (lenderResp) {
                        that.$eventBus.$emit('eventbus-hotlead-step', { trigger: that.options.leadGroupName || that.$options.name, requestName: 'AllianceRealty' });
                    } else {
                        if (this.isMovotoHomeLoans && this.getSplit('movoto-CW-2269') && this.getSplit('movoto-primary-intent-mortgage-CW-1249') && this.dpp && this.dpp.propertyId) {
                            let url = encodeURIComponent(this.dpp.dppUrl);
                            this.$eventBus.$emit('ga', { en: 'opt_in_on_re_lead', ec: 'mortgage_secondary', el: 'Secondary_Mortgage___Opt_In', props: {} });
                            window.location.href = `${this.glb.appUrl}mortgages/onboarding/?mortgageType=buy&zipcode=${this.dpp.geo.zipcode}&state=${this.dpp.geo.state}&city=${this.dpp.geo.city}&price=${this.dpp.price}&dppurl=${url}&trigger=postreoptin`;
                            return;
                        }
                        that.$eventBus.$emit('eventbus-hotlead-step', { trigger: that.options.leadGroupName || that.$options.name });
                    }
                });
            } else {
                this.updatePrevHotlead({
                    trigger: 'error',
                    container: 'error',
                    hotlead: this.hotlead,
                    options: this.options,
                });
                this.formState = 'rejected';
                if (status && status.code) {
                    if (status.code === 34) {
                        this.readEmail = true;
                    }
                    this.errorMsg = this.getErrorHtml(status);
                    this.hasDefaultPhone = false;
                    return;
                }
                this.$eventBus.$emit('dialog-center', {
                    name: 'mvtConfirm',
                    opt: {
                        title: 'ERROR',
                        message: status && (status.detailMessage || status.msg),
                    },
                    trigger: this.trigger,
                });
            }
        },
        errorHandle() {
            this.formState = '';
            this.updatePrevHotlead({
                trigger: 'error',
                container: 'error',
                hotlead: this.hotlead,
                options: this.options,
            });
        },
        checkListing(listing) {
            let acFormErrorMsg = '';
            if (listing) {
                if (this.options.isVeteransHub) {
                    this.hotlead.zipcode = listing.geo.zipcode || '';
                    return acFormErrorMsg;
                }
                var priceRaw = listing.price;
                var deviceType = this.glb.isMobile ? 'Mobile' : 'Desktop';
                var txt = listing.geo.formatAddress + ' ';

                if (this.acFormLeadType === 'Renter') {
                    if (!listing.isRentals) {
                        acFormErrorMsg = 'Property is for sale and not for rent!';
                    }
                } else {
                    if (listing.isRentals) {
                        acFormErrorMsg = 'Property is for rent and not for sale!';
                    }
                }

                if (listing.houseRealStatus === 'ACTIVE' || listing.houseRealStatus === 'PENDING') {
                    acFormErrorMsg = '';
                    var listDate = (listing.listDate && format.dateGetString(listing.listDate).split(' ')[0]) || '-';
                    txt +=
                        'listed on ' +
                        listDate +
                        ' for $' +
                        (format.formatNumberByComma(listing.price) || '-') +
                        ' ' +
                        (listing.bed || '-') +
                        ' Bd, ' +
                        (listing.bath || '-') +
                        ' Ba, ' +
                        (listing.sqftTotal || '-') +
                        ' Sqft, ' +
                        (listing.propertyTypeDisplay || '-') +
                        ' property.';
                } else if (listing.houseRealStatus === 'FOR_RENT') {
                    acFormErrorMsg = '';
                    var listDate = (listing.listDate && Date(listing.listDate).split(' ')[0]) || '-';
                    txt +=
                        'listed on ' +
                        listDate +
                        ' for $' +
                        (format.formatNumberByComma(listing.price) || '-') +
                        ' ' +
                        (listing.bed || '-') +
                        ' Bd, ' +
                        (listing.bath || '-') +
                        ' Ba, ' +
                        (listing.sqftTotal || '-') +
                        ' Sqft, ' +
                        (listing.propertyTypeDisplay || '-') +
                        ' property.';
                } else if (listing.houseRealStatus === 'OFF_MARKET' || listing.houseRealStatus === 'REMOVED') {
                    const isRenter = this.acFormLeadType === 'Renter';
                    acFormErrorMsg = 'Property is a public record and not available!';
                    txt =
                        'Public Record - Not for ' +
                        (isRenter ? 'Rent: ' : 'Sale: ') +
                        listing.geo.formatAddress +
                        (listing.bed || '-') +
                        ' Bd, ' +
                        (listing.bath || '-') +
                        ' Ba, ' +
                        (listing.sqftTotal || '-') +
                        ' Sqft, ' +
                        (listing.propertyTypeDisplay || '-') +
                        ' property.';
                } else if (!listing.id && !listing.redirect && listing.publicRecordId) {
                    acFormErrorMsg = 'Property is a public record and not available!';
                    txt =
                        'Public Record - Not for Sale: ' +
                        listing.geo.formatAddress +
                        (listing.bed || '-') +
                        ' Bd, ' +
                        (listing.bath || '-') +
                        ' Ba, ' +
                        (listing.sqftTotal || '-') +
                        ' Sqft, ' +
                        (listing.propertyTypeDisplay || '-') +
                        ' property.';
                } else {
                    if (!priceRaw) {
                        priceRaw = listing.listPrice;
                    }
                    var soldDate = listing.soldDate && format.dateGetString(listing.soldDate).split(' ')[0];
                    txt +=
                        'Sold on ' +
                        (soldDate || '-') +
                        ' for $' +
                        (format.formatNumberByComma(listing.price) || '-') +
                        ' ' +
                        (listing.bed || '-') +
                        ' Bd, ' +
                        (listing.bath || '-') +
                        ' Ba, ' +
                        (listing.sqftTotal || '-') +
                        ' Sqft, ' +
                        (listing.propertyTypeDisplay || '-') +
                        ' property.';
                }

                if (this.acFormLeadType) {
                    txt += `(${this.acFormLeadType})`;
                }
                this.hotlead.comment = txt;

                if (typeof priceRaw === 'number') {
                    priceRaw = Math.floor(priceRaw);
                } else if (typeof priceRaw === 'string') {
                    priceRaw = parseInt(priceRaw.split('.')[0]);
                }

                this.hotlead.price = priceRaw || 0;
                this.hotlead.enquiryType = 2;
                this.hotlead.zipcode = (listing.geo && listing.geo.zipcode) || this.hotlead.zipcode;
                if (this.acFormLeadType === 'Renter') {
                    const rentalInfo = hlConf[listing.mlsDbNumber];
                    this.hotlead.moveIn = getDefaultDate({ propertyData: listing });
                    this.hotlead.zumperListingId = listing.rentalListingId || '';
                    this.hotlead.rentalListingId = listing.rentalListingId || '';
                    this.hotlead.listingId = listing.listingId || '';
                    this.hotlead.buildingId = listing.rentalBuildingId || '';
                    this.hotlead.floorPlanId = listing.rentalFloorPlanId || '';
                    this.hotlead.type = `${rentalInfo.NewRentalRequestInfo}_${deviceType}`;
                    if (rentalInfo.validator) {
                        Object.keys(rentalInfo.validator).forEach((prop) => {
                            if (!this.hotlead[prop]) {
                                acFormErrorMsg = `Property cannot find ${prop}`;
                            }
                        });
                    }
                } else {
                    this.hotlead.type = this.acFormLeadType === 'Seller' ? 'AnswerConnectSellLead' : 219;
                }
            } else {
                acFormErrorMsg = 'Property cannot be found';
            }
            return acFormErrorMsg;
        },

        showRespective3rdPartyForm(eventName, isAppendUtmCta = false) {
            this.$eventBus.$emit(eventName, { isAppendUtmCta: isAppendUtmCta });
        },

        show3rdPartyForm(id, url) {
            this.$eventBus.$emit('eventbus-pop-3rdparty', {
                id: id,
                url: url,
                zipcode: this.hotlead.zipcode,
                propertyType: this.propertyData && this.propertyData.propertyType,
                price: this.price,
                houseRealStatus: this.propertyData && this.propertyData.houseRealStatus,
                trigger: this.trigger,
            });
        },

        openTip() {
            this.popDialogBox = true;
        },

        closeTip() {
            this.popDialogBox = false;
        },

        getMortgageQA() {
            return {
                employment: this.selectedEmployment,
                income: this.selectedIncome,
                selfAssessedCreditScore: this.selectedCreditScore,
            };
        },
        showVeteranThankYou() {
            if (this.options.isVeteransHub && this.hotlead.price > 50000 && this.hotlead.price < 750000) {
                if (this.isVeteran) {
                    return true;
                }
                return false;
            } else if (this.options.isVeteransHub && (this.hotlead.price === 50000 || this.hotlead.price === 750000)) {
                return false;
            } else {
                return this.isVeteran;
            }
        },
        hasValidGeo(geo) {
            return geo && geo.address != 'Undisclosed' && geo.city && geo.state && geo.zipcode;
        },
        sendEmail() {
            let property = this.propertyData;
            if (
                !property ||
                !property.geo ||
                this.phoneLeadList.includes(this.hotlead.phone) ||
                this.glb.hotleadInfo.phone === this.hotlead.phone ||
                (this.hotlead.email && this.glb.hotleadInfo.email === this.hotlead.email) ||
                (this.hotlead.name && this.glb.hotleadInfo.fullName === this.hotlead.name)
            ) {
                return false;
            }
            this.phoneLeadList.push(this.hotlead.phone);
            let opts = {
                hotlead: this.hotlead,
                address: property.geo.address,
                city: property.geo.city,
                state: property.geo.state,
                price: property.price,
                path: property.path,
            };
            return this.sendPhoneOnlyLeadEmail(opts);
        },
        selectVeteranType() {
            if (this.hotlead.veteransType === 'not-served') {
                this.isVeteran = false;
                this.hotlead.veteransType = '';
            }
        },
    },
    computed: {
        ...mapGetters('glb', []),
        hotleadZipcode() {
            return this.hotlead.zipcode;
        },
        partnerProfile() {
            if (this.partnerProfiles && this.partnerProfiles.length) {
                if (this.partnerProfiles.length === 1) {
                    return this.partnerProfiles[0];
                }
                if (this.partnerProfiles.length > 1) {
                    return this.partnerProfiles.find((item) => {
                        if (item.condition && item.condition.property && item.condition.property === 'isVeteran') {
                            return this.isVeteran === item.condition.value;
                        }
                        return true;
                    });
                }
            }
            const movotoFallback = {
                ...fallbackPartner,
                partnerId: getStaticPartnerId(fallbackPartner.partnerId, this.glb.env),
            };
            return movotoFallback;
        },
        showTermCheckbox() {
            return this.partnerUIId === partnerType.proplus;
        },
        partnerUIId() {
            return this.partnerProfile?.type;
        },
        partnerName() {
            return this.partnerProfile?.partnerName;
        },
        disclaimer() {
            let html = '';
            //tcpa disclaimer
            if (this.partnerUIId) {
                if (this.partnerUIId === partnerType.realtor) {
                    if (this.treatment2903 != 'on') {
                        html += `Al enviar su información, usted acepta que Movoto puede compartir su información con su red de profesionales de bienes raíces e hipotecas (<a class="text-dotted" href="${this.glb.appUrl}about/terms-of-use/#affiliates" target="_blank">afiliados</a>) (o a través de sus agentes) y consentimiento para recibir correos electrónicos de marketing, llamadas y mensajes de texto relacionados con su consulta y otros asuntos relacionados con el hogar (incluso a través de sistema de marcación telefónica automática, o tecnología de voz artificial o pregrabada) de Movoto y sus afiliados y sus redes de proveedores de servicios a la dirección de correo electrónico y/o número que usted proporcionó incluso si su número aparece en el Registro No Llame de una empresa, estado o nacional. Además estás de acuerdo que dichos afiliados puedan compartir información con Movoto y entre sí. También aceptas nuestra <a class="text-dotted" href="${this.glb.appUrl}about/terms-of-use/" target="_blank">Términos y Condiciones</a> y <a class="text-dotted" href="${this.glb.appUrl}about/privacy-policy/" target="_blank">Política de Privacidad</a>. No se requiere consentimiento para la compra. Las tarifas de mensajes/datos pueden aplicar.`;
                    } else {
                        html += 'Su procedimiento nos da el consentimiento a que usted reciba llamadas, mensajes de texto al número que proporcionó, incluyendo mercado tecnico atravez de marcador automático, voz artificial, pregrabada, y correo electrónico de <a class="link" href="https://www.realtor.com/" target="_blank" rel="nofollow noreferrer">Realtor.com</a> (Move Sales, Inc.) sobre su consulta y otros asuntos relacionados con la vivienda, pero sincondición a compra. También acepta los <a class="link" href="https://www.realtor.com/terms-of-service/" target="_blank" rel="nofollow noreferrer">Términos de uso</a> y la <a class="link" href="https://www.realtor.com/privacy-policy/" target="_blank" rel="nofollow noreferrer">Política de privacidad</a> de <a class="link" href="https://www.realtor.com/" target="_blank" rel="nofollow noreferrer">realtor.com</a> con respecto a la información relacionada con usted. Pueden aplicarse tarifas de mensajes/datos. Este consentimiento se aplica incluso si está en una lista corporativa, estatal o nacional de No Llamar "Do Not Call List"';
                    }
                } else {
                    html = `By entering my info and clicking ${this.buttonText} below,  I am providing my e-signature and express written consent to receive marketing emails, calls, and texts (including via automatic telephone dialing systems, AI generative, artificial or pre-recorded voice technology) relating to my inquiry from `;
                    if (this.partnerUIId === partnerType.proplus) {
                        html += `<b class="text-bold">Movoto</b> and <b class="text-bold">selected partners below</b>`;
                    } else {
                        html += `<b class="text-bold">${this.partnerName}</b>`;
                    }
                    html += ` (and parties calling/messaging on their behalf) even if my number appears on the company, state, or national Do Not Call Registry. You also agree to our <a class="text-dotted" target="_blank" href="${this.glb.appUrl}about/terms-of-use/">Terms and Conditions</a> and <a class="text-dotted" target="_blank" href="${this.glb.appUrl}about/privacy-policy/">Privacy Policy</a>. Consent not required to receive services (call 1-844-420-1270 to be connected without providing consent).`;
                }
            }
            //default disclaimer
            if (!html) {
                if (this.hasOJOAgent && this.ojoLeadFormConfig && this.ojoLeadFormConfig.showDisclaimer) {
                    html = this.molang('search.common.ojoLeadFormDisclaimer');
                } else if (this.glb.language === 'es') {
                    html = `Al ingresar mi información y hacer clic en ${this.buttonText} a continuación, estoy proporcionando mi firma electrónica y mi consentimiento expreso por escrito para recibir correos electrónicos, llamadas y mensajes de texto de marketing (incluidos a través de sistemas automáticos de marcación telefónica, tecnología generativa de IA, voz artificial o pregrabada) relacionados con mi consulta de <b class="text-bold">Movoto</b> (y las partes que llaman/mensajéan en su nombre), incluso si mi número aparece en el Registro Nacional, Estatal o de la empresa para No Llamar. También aceptas nuestros <a class="text-dotted" target="_blank" href="${this.glb.appUrl}about/terms-of-use/">Términos y Condiciones</a> y nuestra <a class="text-dotted" target="_blank" href="${this.glb.appUrl}about/privacy-policy/">Política de Privacidad</a>. El consentimiento no es obligatorio para recibir servicios (llame al 1-844-420-1270 para conectarse sin proporcionar consentimiento).`;
                } else {
                    html = `By entering my info and clicking ${this.buttonText} below, I am providing my e-signature and express written consent to receive marketing emails, calls, and texts (including via automatic telephone dialing systems, AI generative, artificial or pre-recorded voice technology) relating to my inquiry from <b class="text-bold">Movoto</b> (and parties calling/messaging on their behalf) even if my number appears on the company, state, or national Do Not Call Registry. You also agree to our<a class="text-dotted" target="_blank" href="${this.glb.appUrl}about/terms-of-use/">Terms and Conditions</a> and <a class="text-dotted" target="_blank" href="${this.glb.appUrl}about/privacy-policy/">Privacy Policy</a>. Consent not required to receive services (call 1-844-420-1270 to be connected without providing consent).`;
                }
            }
            if (this.hasLeadId) {
                let propertyData = this.options.propertyData;
                if (!propertyData || !propertyData.isRentals) {
                    if (this.glb.language === 'es') {
                        html = `<label><input type="hidden" id="leadid_tcpa_disclosure_spanish"/>${html}</label>`;
                    } else {
                        html = `<label><input type="hidden" id="leadid_tcpa_disclosure"/>${html}</label>`;
                    }
                }
            }
            return html;
        },
        newDpp() {
            return this.getSplit('movoto-dpp-redesign-CW-2515', 'on1') || this.getSplit('movoto-dpp-redesign-CW-2515', 'on2');
        },
        visiableDisclaimer() {
            if (this.hasOJOAgent && this.ojoLeadFormConfig && this.ojoLeadFormConfig.showDisclaimer) {
                return false;
            } else if (this.options.hideDisclaimer) {
                return false;
            }
            return true;
        },
        showCashOfferCheckbox() {
            return this.options.showCashOffer || (!this.isCashOfferLead && this.isSellLead) || [2, 4].includes(this.interestedIn);
        },
        ...mapGetters('dpp', ['dpp']),
        ...mapGetters('glb', ['glb', 'getSplit', 'getErrorHtml', 'getPrevHotlead']),
        isCashOfferLead() {
            return this.options.leadGroupName === 'mvtCashOffer';
        },
        showConfirmUI() {
            return (this.options.autoSend || this.isLenderApiLoading) && (this.formState === 'submitting' || this.formState === 'submitted');
        },
        trigger() {
            return this.options.trigger || this.$options.name;
        },
        container() {
            return this.options.container || this.$options.name;
        },
        needCheckListing() {
            return this.isACForm || this.options.isVeteransHub;
        },
        profile() {
            if (!this.options.hideProfile) {
                if (this.options.profileUrl) {
                    return this.options.profileUrl;
                } else if (this.options.agentData) {
                    return this.options.agentData.profilePhotoUrl || this.options.agentData.imageUrl || this.options.agentData.agentImage || this.glb.cdnUrl + 'vimages/assignedAgent.jpg';
                } else if (this.propertyData) {
                    if (this.propertyData.photoCount > 0) {
                        return this.propertyData.tnImgPath;
                    } else {
                        var key = encodeURIComponent(this.propertyData.geo.formatAddress || this.propertyData.geo.lat + '_' + this.propertyData.geo.lng) + '_streetview';
                        if ($.getHashValue(key)) {
                            return $.getHashValue(key);
                        } else if (this.propertyData.mapUrls && this.propertyData.mapUrls.length === 3) {
                            return this.propertyData.mapUrls.find((e) => e.index === 0).src;
                        } else {
                            this.showStreetView = true;
                            return this.propertyData.tnImgPath;
                        }
                    }
                }
                return null;
            } else {
                return null;
            }
        },
        hotleadSup() {
            return this.options.hotleadSup;
        },
        hotleadSub() {
            if (this.options.hotleadSub) {
                return this.options.hotleadSub;
            }
            return '';
        },
        getShowAddress() {
            return this.showAddress || [6].includes(this.hotlead.enquiryType);
        },
        isLogin() {
            return !!(this.glb.user && this.glb.user.id);
        },
        disableSend() {
            if (['submitting', 'submitted'].includes(this.formState)) {
                return true;
            }
            if (this.isACForm) {
                if (!this.acFormLeadType) {
                    return true;
                }
            } else {
                return false;
            }
        },
        opts() {
            return {
                ...this.defaultOptions,
                ...this.options,
            };
        },
        isAgentCardShow() {
            return this.options.agentData && !this.options.hideAgent;
        },
        isACForm() {
            return this.options.isACForm || false;
        },
        showConcierge() {
            return this.glb.agent.id && this.options.showConcierge;
        },
        isAgentEnquiry() {
            return [1, 2].includes(this.hotlead.enquiryType);
        },
        showInputGeo() {
            return this.options.showGeo || this.isAgentEnquiry;
        },
        showInputGeoOptional() {
            return [5].includes(this.hotlead.enquiryType);
        },
        isPropertyCardShow() {
            return this.propertyData && !this.options.hideProperty;
        },
        hasOJOAgent() {
            return this.glb.agent.id && !this.glb.rentals;
        },
        ojoLeadFormConfig() {
            if (this.glb.agent.id) {
                return this.options.ojoFormConfigs;
            }
            return null;
        },
        showEnquiryInput() {
            return this.options.hotleadType == 'ContactMovotoDesktop' || this.options.hotleadType == 'ContactMovotoMobile';
        },
        showBuyAndSell() {
            return this.propertyData && (this.propertyData.isPrOnly || !this.propertyData.listingId || this.propertyData.isSold) && this.options.showBuySold && !this.hasOJOAgent;
        },
        price() {
            if (this.hotlead && this.hotlead.price) {
                return this.hotlead.price;
            }
            if (this.propertyData && this.propertyData.price) {
                return this.propertyData.price;
            }
            return 0;
        },
        agentId() {
            return this.glb.agent.id;
        },
        agentPhone() {
            return (this.options.fromMyAgent && this.options.agentData && this.options.agentData.phone) || this.glb.agent.phone;
        },
        getVeteranCheckboxData() {
            let chckboxTxt = this.molang('dpp.hotleadForm.veterancheckbox');
            return [
                {
                    text: chckboxTxt,
                    value: true,
                    defaultValue: false,
                },
            ];
        },
        getTermsCheckboxData() {
            return [
                {
                    text: `<b class="text-bold">${this.partnerName}</b> will contact me in response to my message.`,
                    value: true,
                    defaultValue: false,
                },
            ];
        },
        getMovotoHomeLoansCheckboxData() {
            let chckboxTxt = this.molang('dpp.hotleadForm.connectMovotoHomeLoans');
            if (this.treatment['movoto-mhl-copy-update-CW-2302'] === 'on1') {
                chckboxTxt = this.molang('dpp.hotleadForm.connectMovotoHomeLoans_on1');
            } else if (this.treatment['movoto-mhl-copy-update-CW-2302'] === 'on2') {
                chckboxTxt = this.molang('dpp.hotleadForm.connectMovotoHomeLoans_on2');
            }
            return [
                {
                    text: chckboxTxt,
                    value: true,
                },
            ];
        },
        getCashOfferCheckboxData() {
            return [
                {
                    text: `I'm interested in getting a cash offer`,
                    value: true,
                },
            ];
        },
        isSellLead() {
            return !!this.glb.sellLeadType.find((item) => {
                return item.indexOf(this.hotlead.type) > -1;
            });
        },
        showMovotoHomeLoans() {
            if (this.options.propertyData && this.options.propertyData.isRentals) {
                return false;
            }

            return this.getSplit('movoto-primary-intent-mortgage-CW-1249', 'on1');
        },
        showVeteransOption() {
            if (this.partnerProfiles && this.partnerProfiles.length) {
                return !!this.partnerProfiles.find((item) => item.type === partnerType.veterans);
            }
        },
        newDppButtonClass() {
            if (this.newDpp) {
                if (!this.hotlead.email || !this.hotlead.phone || !this.hotlead.name) {
                    return 'btn tertiary submit-dim-gray';
                } else {
                    return 'btn primary';
                }
            }
            return this.buttonClass;
        },
    },
    watch: {
        'split.ready.value'(ready) {
            if (ready) {
                this.updateSplit();
            }
        },
        hotleadZipcode(nVal, oVal) {
            if (nVal && nVal !== oVal && this.initialed) {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    this.reloadPartner();
                }, 500);
            }
        },
        assignedAgent() {
            this.updateHotleaUI();
        },
        calendar(newValue) {
            this.updateHotleaUI();
        },
        agentId(newValue) {
            this.updateHotleaUI();
        },
        messageAgentLeadType(nVal) {
            if (nVal) {
                this.showBuySellError = false;
                this.showComment = nVal === 'other';
                if (this.showComment) {
                    this.hotlead.comment = '';
                }
                this.setAgentLeadType(nVal);
            }
        },
        addressInput(newValue) {
            if (newValue && newValue.geo) {
                const addressComponents = newValue.geo.address_components;
                //set zipcode, state
                if (addressComponents) {
                    //get address by google address_components
                    let address = [];
                    addressComponents.forEach((item) => {
                        if (item.types.includes('postal_code')) {
                            this.hotlead.zipcode = item.long_name;
                        } else if (item.types.includes('administrative_area_level_1')) {
                            this.hotlead.state = item.short_name;
                        } else if (item.types.includes('locality')) {
                            this.hotlead.cityName = item.long_name;
                        } else if (item.types.includes('street_number')) {
                            address.push(item.long_name);
                        } else if (item.types.includes('route')) {
                            address.push(item.long_name);
                        }
                    });
                    this.hotlead.address = address.join(' ');
                }
            }
        },
        interestedIn(nVal, oVal) {
            if (nVal != oVal && nVal) {
                this.$eventBus.$emit('ga', { en: 'click', ec: this.$options.name, el: this.interestOptions[nVal].text, props: { link_name: this.interestOptions[nVal].text } });
                if ([2, 3, 4, 5].includes(nVal)) {
                    switch (nVal) {
                        case 3:
                            this.hotlead.type = !this.glb.user.activated ? 'PostSignupBuyer' : 'InlineHotleadInStackFormSEMLandingPage';
                            break;
                        case 2:
                        case 4:
                            this.hotlead.type = !this.glb.user.activated ? 'PostSignupSeller' : 'ChatBotSell';
                            break;
                        case 5:
                            this.hotlead.type = 'ChatBotBuy';
                    }
                } else {
                    this.buttonText = 'Connect';
                }
            }
        },
    },
    mounted() {
        this.hasLeadId = window.xLeadId;
        this.propertyData = this.options.propertyData;
        this.buttonClass = this.options.buttonClass;
        this.createHotleadModel();
        if (this.split.ready.value) {
            this.updateSplit();
        }
        if (this.options.agentData) {
            this.options.agentData.rating = this.options.agentData.averageRating || this.options.agentData.rating;
        }
        if (this.newDpp) {
            //hack code for new dpp CW-2488
            this.updateSubmitBtnClass();
        }
        $(this.$el).on('change', 'input', () => {
            if (this.newDpp) {
                //hack code for new dpp CW-2488
                this.updateSubmitBtnClass();
            }
        });
        $(this.$el).on('focus', 'input', () => {
            this.errorMsg = '';
            if (this.newDpp) {
                //hack code for new dpp CW-2488
                this.updateSubmitBtnClass();
            }
        });
        $(this.$el).on('focus', 'select', () => {
            this.errorMsg = '';
        });
        this.loadXlead = true;
        $(this.$el).on('focusout', 'input, textarea, select, a', () => {
            if (this.newDpp) {
                //hack code for new dpp CW-2488
                this.updateSubmitBtnClass();
            }
            this.$eventBus.$emit('xlead-form-snap');
        });

        this.reloadPartner().then(() => {
            this.initialed = true;
            if (this.options.autoSend && !this.getSplit('movoto-leadconfirmation-CW-2152') && this.checkAutoSend()) {
                this.submit();
            }
        });
    },
    beforeUnmount() {
        $(this.$el).off('focusout');
        $(this.$el).off('focus');
    },
};
